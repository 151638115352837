<template>
  <div class="container">
    <section id="objectifs">
      <h3 class="titreObjectif text_gras">Objectifs</h3>
      <ul>
        <li>
          <img
            src="../assets/images/approval-14.png"
            alt="icon approval"
          />Expertise technique web
          <span class="text_gras">PHP Symfony Python</span>
        </li>
        <li>
          <img
            src="../assets/images/approval-14.png"
            alt="icon approval"
          />Pilotage des applications
        </li>
        <li>
          <img
            src="../assets/images/approval-14.png"
            alt="icon approval"
          />Direction opérationnelle des projets
        </li>
      </ul>
      <p class="text_gras">15 ans d'expérience professionnelle</p>
      <p class="text_gras">
        <span class="text_souligne">Mots clés</span> : PHP, Symfony, Python,
        JavaScript, TypeScript, REST, Redis, Chiffrement AES, Matomo, Docker,
        SQL, PL/SQL, Bash, XML, Apache 2 / Nginx, PowerBi
      </p>
      <p>
        <span class="text_gras text_souligne">Bonnes connaissances </span> :
        vueJs, React, Angular
      </p>
    </section>

    <div class="card">
      <h3 class="card-header">Formations</h3>
      <div class="card-body">
        <p>
          <span class="text_gras">01/2023:</span>Formation en administration
          fonctionnelle de Legisway Entreprise (Wolters Kluwer)
        </p>
        <p>
          <span class="text_gras">01/2020:</span> Formation en Assurances
          Collectives chez Groupama Gan Vie (GGVIE)
        </p>
        <p><span class="text_gras">11/2019 : VBA/Excel</span> chez Orsys</p>
        <p>
          <span class="text_gras">10/2019 : SCRUM Master</span> chez Open
          Instiut
        </p>
        <p>
          <span class="text_gras">10/2019 : Python3</span> chez Open Institut
        </p>
        <p>
          <span class="text_gras">03/2019 : Angular 7</span> chez Open Institut
        </p>
        <p>
          <span class="text_gras">12/2018 : Kony Front-End Developer</span>
          Certified Expert chez Ascensi
        </p>
        <p>
          <span class="text_gras">06/2011 : Zend Framework 1.11</span> Chez
          Orsys
        </p>
        <p>
          <span class="text_gras"
            >05/2011 : Certification ITIL V3 Foundations
          </span>
        </p>
        <p>
          <span class="text_gras">2007/2008 : Certifications CISCO CCNA4</span>
          administration des réseaux informatiques
        </p>
      </div>
    </div>

    <div class="card">
      <h3 class="card-header">Cursus</h3>
      <div class="card-body">
        <p>
          <span class="text_gras"
            >2008/2009 : Mastère Spécialisé en Informatique: Systèmes
            d'Informations - INSA de Lyon</span
          >
        </p>
        <p>
          2005/2008 : Ingénieur en Génie Industriel - Ecole Nationale
          d'Ingénieurs de Bamako au MALI
        </p>
        <p>
          2002/2004 : DUT en Génie Electrique de l'Institut Universitaire de
          Technologie de Bobo au Burkina Faso
        </p>
        <p>
          2001/2002 : Baccalauréat Technologique au Lycée Technique de
          Ouagadougou au Burkina Faso
        </p>
      </div>
    </div>
  </div>
  <!-- ./container -->
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
section#objectifs {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 2px 5px 5px gray;
}
.titreObjectif,
.text_souligne {
  text-decoration: underline;
}
.text_gras {
  font-weight: bold;
}
div.card {
  margin-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

h3.card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #11304d;
  color: white;
  transition: 0.5s;
}
h3.card-header:hover {
  background-color: white;
  color: #11304d;
  transition: 0.5s;
}
div.card-body:hover {
  background-color: #11304d;
  color: white;
  transition: 0.5s;
}
</style>
