<template>
  <footer>
    <div id="reseau">
      <div class="box">
        <a href="https://www.facebook.com/joseph.silga/" target="_blank"
          ><i class="fa-brands fa-facebook"></i>
          <h4>Facebook</h4>
          <span>Ma page Facebook </span>
        </a>
      </div>
      <div class="box">
        <a href="https://gitlab.com/j_silga" target="_blank"
          ><i class="fa-brands fa-gitlab"></i>
          <h4>Gitlab</h4>
          <span>Mon file Gitlab </span>
        </a>
      </div>
      <div class="box">
        <a
          href="https://www.linkedin.com/in/silga-joseph-2024-jsi-consulting"
          target="_blank"
          ><i class="fa-brands fa-linkedin"></i>
          <h4>LinkedIn</h4>
          <span>Mon profil LinkedIn</span>
        </a>
      </div>
      <div class="box">
        <a href="#"
          ><i class="fa-solid fa-envelope"></i>
          <h4>Contact</h4>
          <span>Contactez-moi</span>
        </a>
      </div>
    </div>
    <p>
      <span>JSI CONSULTING</span> &nbsp; &copy; 2022 - {{ currentYear }} &nbsp;
      Tous droits réservés
    </p>
    <p>Transformer vos idées en solutions digitales</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background-color: #11304d;
  grid-area: footer;
  text-align: center;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
footer span {
  font-style: italic;
}
footer p {
  margin-bottom: 7px;
}

/*Résau sociaux */
#reseau {
  display: flex; /*La zone nav, on en fait un conteneur */
  justify-content: center;
}
.box a {
  color: #f5a31f;
  text-decoration: none;
}
.box {
  width: 25%;
  height: 70px;
  position: relative; /* width,  height et pos relative nécessaire pour la superpositon */
  display: flex;
  justify-content: center;
  align-items: center;
}
.box h4,
.box span {
  position: absolute; /* pos absolute, indispensable pour faire de la superposition  */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* cacher */
}

.box h4 {
  font-size: 15px;
  text-transform: uppercase;
  top: 5px;
}
.box span {
  color: white;
  font-size: 12px;
  bottom: 5px;
}

.box i {
  font-size: 22px;
  transition: 0.3s;
}
.box:hover i {
  transform: scale(4); /* 4* fois plus gros */
  opacity: 0;
  transition: 0.3s;
}

.box:hover h4 {
  opacity: 1;
  top: 15px;
  transition: 0.5s;
}
.box:hover span {
  opacity: 1;
  bottom: 5px;
  transition: 0.5s;
}
</style>
