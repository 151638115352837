<template>
  <div>
    <section id="">
      <div class="enteteMission">
        <div class="imgClient">
          <h3><span class="styletext">Client</span> : {{ mission.client }}</h3>
          <img
            class="logo"
            :src="`/images/${mission.image}`"
            :alt="`logo du client ${mission.client}`"
          />
        </div>
        <div class="infoClient">
          <p><span class="styletext">Service</span>: {{ mission.service }}</p>
          <p>
            <span class="styletext">Lieu</span>: {{ mission.ville }} ({{
              mission.cp
            }})
          </p>
          <p>
            <span class="styletext">Début</span>:
            {{ formatDate(`${mission.date_debut}`) }}
          </p>
          <p>
            <span class="styletext">Fin</span>:
            {{ formatDate(`${mission.date_fin}`) }}
          </p>
          <p v-if="mission.client === 'GGVIE'">
            <span class="text_gras">Mission 2 : janv.2023 - déc. 2024</span>
          </p>
          <p v-if="mission.client === 'EDF'">
            <span class="text_gras">Mission 2 : janv.2022 - déc. 2023</span>
          </p>
        </div>
      </div>

      <article v-for="project in mission.projets">
        <ProjetView :projet="project" />
      </article>
    </section>
  </div>
</template>

<script>
import ProjetView from "@/views/ProjetView.vue";
import datas from "@/datas.json";

const months = [
  "Janv.",
  "Fev.",
  "Mar.",
  "Avr.",
  "Mai",
  "Jui.",
  "Jul.",
  "Aou.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
];

export default {
  data() {
    return {
      months: months,
    };
  },
  props: {
    /*Ce props est récupéré sur la route */
    id: { type: Number, required: true },
    slug: { type: String, required: true },
  },
  computed: {
    mission() {
      return datas.missions.find((mission) => mission.id === this.id);
    },
  },
  components: {
    ProjetView,
  },
  methods: {
    formatDate: function (strDate) {
      if (strDate.length == 0) return "";
      let d = new Date(strDate);
      return this.months[d.getMonth()] + " " + d.getFullYear();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.enteteMission {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 2px 5px 5px gray;
  display: flex;
  justify-content: center;
}
div.infoClient {
  padding-left: 15px;
}
.styletext {
  text-decoration: underline;
  font-weight: bold;
}
img {
  width: 160px;
  height: 60px;
  background-size: cover;
  border-radius: 10px;
}
</style>
