import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import MissionView from '@/views/MissionView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

import datas from '@/datas.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {transition : 'bounce'}
  },
  {
    path: '/mission/:id/:slug',
    name: 'mission.show',
    component: MissionView,
    props: (route) => ({id: parseInt(route.params.id), slug: route.params.slug }),
    meta: {transition : 'bounce'},
    beforeEnter: (to, from) => {
      const mission = datas.missions.find(mission => mission.id == parseInt(to.params.id))
      if(!mission){
        console.log("Mission non trouvée!")
        alert("Mission non trouvée! Vous serez redirigé vers la page d'accueil")
        return { redirect : {name: 'home'}}
      }
     
    }
  },
  {
    path:"/:cathAll(.*)",
    component: NotFoundView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "lien-actif"
})

export default router

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import '@/assets/fontawesome-free-6.1.1-web/css/all.min.css' /* à installer npm install ? */
//import '@/assets/animate.css-main/animate.min.css'
import '@/assets/style.css'