<template>
        <div class="card">
            <h3 class="card-header"><span class="styletext">Projet</span>  : {{ projet.nom }}</h3>
            <div class="card-body">
                <h5>{{ projet.description }}</h5>
                <p>Contributions:</p>
                <ul>
                    <li v-for="contribution in projet.contributions"> <img src="../assets/images/approval-14.png" alt="icon approval" /> {{ contribution }}</li>
                </ul>
                <p>Environnement technique:</p>
                <span v-for="environnement in projet.environnement_technique">
                    {{ environnement }} &nbsp;
                </span>
            </div>
        </div>
</template>

<script>

export default {
  name: 'ProjetView',
  props: { projet: Object }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.card{
  margin-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

h3.card-header{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
	background-color:#11304d;
  color: white;
  transition: 0.5s;
}
h3.card-header:hover{
	background-color: white;
  color: #11304d;
  transition: 0.5s;
}
div.card-body:hover{
	background-color:#11304d;
  color: white;
  transition: 0.5s;
}
p{
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 2px;
}
span{
    font-weight: bold;
}
.styletext{
    text-decoration: underline;
    font-weight: bold;
}
</style>