<template>
  <div class="container">
    <div class="notFound">
      <p><i class="fa-solid fa-face-pensive"></i></p>
      <h1> Oups, désolé, page non trouvée !<font-awesome-icon icon="fa-solid fa-face-pensive" /></h1>
      <p>Revenir à la  <router-link to="/" class="btn btnBack">page d'accueil</router-link> </p>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
div.notFound{
  border: 2px solid red;
  border-radius: 25px;
  padding: 20px;
}
.btnBack{
  background-color: #11304d;
  color: white;
  transition: 0.5s;
}
.btnBack:hover{
  font-weight: bold;
  background-color: white;
  color: #11304d;
  border: 2px solid  #11304d;
  transition: 0.5s;
}
</style>