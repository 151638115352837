<template>
  <div id="menuNavigation">
    <MenuView />
  </div>

  <TitreView />
  <InfosPersonnellesView />
  <FooterView />

  <router-view v-slot="{ Component }">
    <transition name="bounce">
      <component :is="Component" :key="$route.path"></component>
    </transition>
  </router-view>
</template>

<script>
import MenuView from "@/components/MenuView.vue";
import InfosPersonnellesView from "@/views/InfosPersonnellesView.vue";
import TitreView from "@/views/TitreView.vue";
import FooterView from "@/views/FooterView.vue";

import datas from "@/datas.json";

export default {
  /*data(){
  return {
    missions: datas.missions
  }
 },
 computed: {
    missionId(){
      return parseFloat(this.$route.params.id)
    },
    mission(){
      return datas.missions.find(mission => mission.id = this.missionId)
    }
 },*/

  components: {
    MenuView,
    TitreView,
    InfosPersonnellesView,
    FooterView,
  },
};
</script>

<style></style>
