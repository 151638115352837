<template>
  <div id="blocGauche">
    <div id="strickyVertical">
      <section id="image">
        <span class="photo"></span>
      </section>
      <hr />
      <div id="scrollVertical">
        <section id="details_personnels">
          <div>
            <h3>Contacts</h3>
            <p>
              <img
                class="details"
                src="../assets/images/user-24.png"
                alt="icône utilisateur"
              />&nbsp;J. SILGA
            </p>
            <p>
              <img
                class="details"
                src="../assets/images/mail-24.png"
                alt="icône email"
              />&nbsp;<a href="mailto:silga.dev@gmail.com"
                >silga.dev@gmail.com</a
              >
            </p>
            <p>
              <img
                class="details"
                src="../assets/images/tel-24.png"
                alt="icône telephone"
              />&nbsp;+33 6 43 66 94 75
            </p>
            <p>
              <img
                class="details"
                src="../assets/images/adresse-24.png"
                alt="icône adresse"
              />&nbsp;95300, Pontoise
            </p>
            <p>
              <img
                class="details"
                src="../assets/images/voiture-24.png"
                alt="icône permis"
              />&nbsp;Permis B
            </p>
            <p>
              <img
                class="details"
                src="../assets/images/linked-24.png"
                alt="icône linked"
              />&nbsp;<a
                href="https://www.linkedin.com/in/silga-joseph-2024-jsi-consulting"
                target="_blank"
                >LinkedIn</a
              >
            </p>
          </div>
        </section>
        <hr />
        <section id="disponibilite">
          <h3>Disponibilité</h3>
          <p>Janv. 2025</p>
        </section>
        <hr />
        <section id="site_web">
          <h3>Site web</h3>
          <p><a href="https://josephsilga.net">josephsilga.net</a></p>
          <p>
            <a href="https://avenuedushopping.com" target="_blank"
              >avenuedushopping.com</a
            >
          </p>
          <p>
            <a
              href="https://admin.avenuedushopping.com/fr/login"
              target="_blank"
              >admin.avenuedushopping</a
            >
          </p>
        </section>
        <hr />
        <section id="langues">
          <h3>Langues</h3>

          <p>Français</p>
          <p>Anglais</p>
        </section>
        <hr />
        <section id="qualites">
          <h3>Qualités</h3>
          <p>Autonome</p>
          <p>Réactif</p>
          <p>Curieux</p>
        </section>
        <hr />
        <section id="defauts">
          <h3>Défauts</h3>
          <p>Touche à tout</p>
        </section>
        <hr />
        <section id="centres_interets">
          <h3>Centres d'intérêts</h3>
          <ul>
            <li>web</li>
            <li>Lecture</li>
            <li>Football</li>
            <li>Natation</li>
            <li>Politique</li>
          </ul>
        </section>
        <hr />
        <section id="personnes_contacter">
          <h3>Personnes à contacter</h3>
          <p>Jean-Michel C.</p>
          <p>Arnaud D.</p>
        </section>
      </div>
    </div>
  </div>
  <!--/blocGauche-->
</template>

<script>
export default {
  name: "InfosPersonnellesView",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section#image {
  /*centrer #image contenant le span.photo */
  text-align: center;
  margin-top: 5px;
}
span.photo {
  background-image: url("../assets/images/joseph_370x493.jpg");
  background-size: cover; /* Couvre au mieux l'espace qui t'es alloué */
  width: 160px;
  height: 160px;
  margin: auto;
  display: inline-block;
  border-radius: 50%; /*Arrondi le span */
  border: 3px solid white;
}

#blocGauche ul,
p {
  margin: 0;
  padding: 0;
}
#blocGauche section ul,
#blocGauche section:not(#details_personnels) p {
  text-align: center;
}

#blocGauche section:hover {
  background-color: white;
}
span.photo:hover {
  border: 3px solid rgb(3, 244, 3);
}
div#scrollVertical section {
  padding-left: 10px;
}
div#scrollVertical {
  height: 450px;
  overflow: auto;
}
div#strickyVertical {
  position: sticky; /*La propriété sticky a besoin d'un point pour bloquer */
  top: 0; /* Définir un point de blocage */
}
#blocGauche h1,
h2,
h3 {
  text-align: center;
}
#blocGauche ul li {
  list-style: none;
}
#blocGauche img {
  vertical-align: middle;
}

div#strickyVertical {
  position: sticky; /*La propriété sticky a besoin d'un point pour bloquer */
  top: 0; /* Définir un point de blocage */
}

#blocGauche {
  background-color: #bdc3c7;
  grid-area: blocGauche;
  border-radius: 10px;
}
#blocGauche section {
  margin: 2px;
  border-radius: 10px;
}

#blocGauche h3 {
  font-family: "Lobster", cursive;
}

section#details_personnels {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  div#scrollVertical {
    height: 100%;
    overflow: none;
  }
  div#strickyVertical {
    position: static;
  }
}
</style>
