<template>
  <div id="titreCV">
    <h2 class="">Développeur Full Stack spécialisé sur PHP/Symfony/Python</h2>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'TitreView',
  data(){
    return {
     
    }
  },
  components: {
   
  }
}
</script>

<style scoped>
#titreCV{
    background-color:#11304d;
    color: white;
    border-radius: 10px;
    position: sticky;  /*Bloquer */
  	top: 0 ; 
    z-index: 1;
}

</style>
